<template>
  <div>
    <div class="container">
      <div
        :class="
          message.user_id === userData.id
            ? 'row justify-content-end mb-1'
            : 'row mb-1'
        "
        v-for="message in messages"
        :key="message.id"
      >
        <div class="card">
          <div class="card-body p-2 text-right">
            <span style="font-size: 13px" class="chat-message d-block mr-8">{{
              message.message
            }}</span>
            <span style="font-size: 10px" class="text-muted">{{
              setDate(message.created_at)
            }}</span>
          </div>
        </div>
      </div>
      <!--  -->

      <!--  -->
    </div>
    <!-- Background Type Message -->
    <div class="row" v-if="btnAccess">
      <div class="col-12 scroll-to-here">
        <div class="card card-type-message shadow">
          <div class="card-body p-2"></div>
        </div>
      </div>
    </div>
    <!-- Form Type Message -->
    <div class="row" v-if="btnAccess">
      <div class="container">
        <div class="type-message">
          <div class="col-12">
            <form @submit.stop.prevent="formOnsubmit()">
              <div class="row justify-content-center mt-1">
                <div class="col-10">
                  <!-- <b-form-group id="input-group-message">
                    <b-form-textarea
                      @keydown.enter.exact.prevent
                      @keyup.enter.exact="checkMessage"
                      @keydown.enter.shift.exact="newline"
                      id="input-message"
                      v-model="form.message"
                      placeholder="Ketik pesan"
                      rows="1"
                      max-rows="8"
                    ></b-form-textarea>
                    <small class="text-danger">{{ error.message }}</small>
                  </b-form-group> -->
                  <div class="wrapper-emoji" v-if="btnAccess">
                    <textarea
                    @keydown.enter.exact.prevent
                      @keyup.enter.exact="checkMessage"
                      @keydown.enter.shift.exact="newline"
                      class="regular-input"
                      v-model="form.message"
                    ></textarea>

                    <emoji-picker @emoji="insert" :search="search">
      <div
        class="emoji-invoker"
        slot="emoji-invoker"
        slot-scope="{ events: { click: clickEvent } }"
        @click.stop="clickEvent"
      >
        <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0h24v24H0z" fill="none"/>
          <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"/>
        </svg>
      </div>
      <div slot="emoji-picker" slot-scope="{ emojis, insert }">
        <!-- <div class="emoji-picker" :style="{ top: display.y + 'px', left: display.x + 'px' }"> -->
        <div class="emoji-picker emoji-picker-position">
          <div class="emoji-picker__search">
            <input type="text" v-model="search" v-focus>
          </div>
          <div>
            <div v-for="(emojiGroup, category) in emojis" :key="category">
              <h5>{{ category }}</h5>
              <div class="emojis">
                <span
                  v-for="(emoji, emojiName) in emojiGroup"
                  :key="emojiName"
                  @click="insert(emoji)"
                  :title="emojiName"
                >{{ emoji }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </emoji-picker>


                  </div>
                </div>
                <div class="col-1">
                  <b-button
                    :disabled="form.message == '' ? true : false"
                    variant="primary"
                    type="submit"
                    class="text-white"
                    v-if="btnAccess"
                  >
                    <i class="fas fa-paper-plane px-0"></i>
                  </b-button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import { getUser, setUser } from "@/core/services/jwt.service.js";
import EmojiPicker from "vue-emoji-picker";

export default {
  components: {
    EmojiPicker,
  },
  data() {
    return {
      search: "",
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      messages: [],
      // insert
      messageTo: [],
      form: {
        message: "",
        message_to: "",
        user_id: getUser().id,
      },
      error: {
        message: "",
        message_to: "",
        user_id: "",
      },
      // other
      message_to_id: '',
      userData: getUser(),
      dataLoaded: false,
      conversationCollectionId: [],
      conversationCollectionIdForm: {
        id: "",
      },
      readConversation: {
        id: [],
      },
      // access
      btnAccess: false
    };
  },
  methods: {
    insert(emoji) {
      //(emoji)
      this.form.message += emoji;
      //("form", this.form)
    },
    checkMessage() {
      if (
        this.form.message !== "" &&
        this.form.message !== "/n" &&
        this.form.message !== "/n/n" &&
        this.form.message !== "/n/n/n" &&
        this.form.message !== "/n/n/n/n" &&
        this.form.message !== "/n/n/n/n/n" &&
        this.form.message !== "/n/n/n/n/n/n"
      ) {
        this.formOnsubmit();
      } else {
        this.resetFormnOnNew();
      }
    },
    newline() {
      this.form.message = `${this.form.message}\n`;
    },
    send() {
      //("========");
      //(this.form.message);
      //("========");
    },
    keyPress(evt) {
      if (evt.key == "Enter" && evt.shiftKey == false) {
        if (this.form.message != "") {
          evt.preventDefault();
          this.formOnsubmit();
        } else if (this.form.message == "") {
          this.form.message = ``;
          //("ini jalan", this.form);
        }
      } else if (evt.key == "Enter" && evt.shiftKey == true) {
      }
    },
    async getMessage(message_to, user_id) {
      let filterParams = ``;
      let response = await module.paginate(
        `api/conversations/${message_to}/${user_id}`,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );

      this.messages = response.data;
      this.dataLoaded = true;

      setTimeout(() => {
        this.scrollToBottom({ behavior: "smooth" });
      }, 100);
    },
    scrollToBottom(options) {
      // window.scrollTo(0, 0);
      let el =
        this.$el.getElementsByClassName("chat-message")[
          this.messages.length - 1
        ];
      // el = el ?? this.$el.getElementsByClassName("card-type-message")[0];
      if (el) {
        el.scrollIntoView(options);
      }
    },
    resetFormnOnNew() {
      let self = this;
      Object.keys(this.form).forEach(function (key, index) {
        self.form[key] = "";
      });
    },
    async formOnsubmit() {
      this.form.message_to = this.message_to_id;
      this.form.user_id = getUser().id;
      //("form", this.form)
      // Make Request
      let response = await module.submitWithoutAlert(
        this.form,
        "api/conversations"
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        // Swal.fire(response.success.title, response.success.message, "success");
        this.getMessage(
          this.$route.params.message_to,
          this.$route.params.user_id
        );
        this.resetFormnOnNew();
        this.$root.$emit("refreshListChatUser");
      }
    },
    setDate(date) {
      let dateTime = new Date(date).toTimeString();
      let dateSplit = dateTime.split(" ")[0];
      return dateSplit.split(":")[0] + ":" + dateSplit.split(":")[1];
    },
    async readMessage(user) {
      //("read message");
      if (user.conversations.length > 0) {
        for (let a = 0; a < user.conversations.length; a++) {
          this.conversationCollectionIdForm.id = user.conversations[a].id;
          this.conversationCollectionId.push(user.conversations[a].id);
          // Read Message
          this.readConversation["_method"] = "put";
          if (a + 1 == user.conversations.length) {
            this.readConversation["_method"] = "put";
            this.readConversation.id = this.conversationCollectionId;
            let response = await module.submitUpdateWithoutAlert(
              this.readConversation,
              `api/conversations/read-messages`
            );
            this.$root.$emit("refreshListChatUser");
          }
        }
      }
    },
    // access
    checkAccess() {
      let access_right_user = window.localStorage.getItem("access_right_display")
      let access_right = JSON.parse(access_right_user)
      for (let a = 0; a < access_right.length; a++) {
        if (access_right[a] == "5002") {
          this.btnAccess = true
        }
      }
    },
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },
  mounted() {
    this.checkAccess()
    // this.form.message_to = this.$route.params.message_to
    this.message_to_id = this.$route.params.message_to
    // this.$store.dispatch(SET_BREADCRUMB, [{ title: this.messageTo.name, purpose: "message" }]);
    this.form.message_to = this.$route.params.message_to
    this.getMessage(this.$route.params.message_to, this.$route.params.user_id);
    // this.$root.$emit("setFirstTimeOpenConversation")
    this.$root.$on("setMessageTo", (user) => {
      this.getMessage(this.$route.params.message_to, this.$route.params.user_id);
      this.messageTo = user;
      //("message to", this.messageTo)
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: user.name,
          role: user.role_name,
          purpose: "message",
        },
      ]);
      this.readMessage(user);
      //("emit message")
    });
  },
  watch: {
    // messages: function (newVal, oldVal) {
    //   // watch it
    //   //("Message changed: ", newVal, " | was: ", oldVal);
    //   this.scrollToBottom({ behavior: "smooth" });
    // },
  },
  beforeRouteUpdate(to, from, next) {
    // this.$store.dispatch(SET_BREADCRUMB, [{ title: to.params.message_to, purpose: "message" }]);
    this.getMessage(to.params.message_to, to.params.user_id);
    this.message_to = to.params.message_to
    next();
  },
};
</script>

<style scoped>
.type-message {
  position: fixed;
  bottom: 12px;
  width: 68%;
  height: 80px;
}

.card-type-message {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80px;
}

.card-form {
  border: none;
}

.wrapper-emoji {
  position: relative;
  display: inline-block;
}

.regular-input {
  padding: 0.5rem 1rem;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 450%;
  height: 4rem;
  outline: none;
}

.regular-input:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.emoji-invoker {
  position: absolute;
  top: 0.5rem;
  left: 430%;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
}
.emoji-invoker:hover {
  transform: scale(1.1);
}
.emoji-invoker > svg {
  fill: #b1c6d0;
}

.emoji-picker {
  position: absolute;
  z-index: 1;
  font-family: Montserrat;
  border: 1px solid #ccc;
  width: 15rem;
  height: 20rem;
  overflow: scroll;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  background: #fff;
  box-shadow: 1px 1px 8px #c7dbe6;
}
.emoji-picker__search {
  display: flex;
}
.emoji-picker__search > input {
  flex: 1;
  border-radius: 10rem;
  border: 1px solid #ccc;
  padding: 0.5rem 1rem;
  outline: none;
}
.emoji-picker h5 {
  margin-bottom: 0;
  color: #b1b1b1;
  text-transform: uppercase;
  font-size: 0.8rem;
  cursor: default;
}
.emoji-picker .emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.emoji-picker .emojis:after {
  content: "";
  flex: auto;
}
.emoji-picker .emojis span {
  padding: 0.2rem;
  cursor: pointer;
  border-radius: 5px;
}
.emoji-picker .emojis span:hover {
  background: #ececec;
  cursor: pointer;
}

.emoji-picker-position{
  /* style="position: absolute; bottom: 70px; left: 550px;" */
  position: absolute;
  bottom: 70px;
  left: 550px;
}

.v-badge__badge {
  background-color: green !important;
}

/* mobile */
@media (max-width: 767.98px) { 
  .regular-input {
  padding: 0.5rem 1rem;
  border-radius: 3px;
  border: 1px solid #ccc;
  width: 110%;
  height: 4rem;
  outline: none;
}

.emoji-invoker {
  position: absolute;
  top: 0.5rem;
  left: 98%;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s;
}
.type-message {
  position: fixed;
  bottom: 12px;
  width: 88%;
  height: 80px;
}

.emoji-picker-position{
  position: absolute;
  bottom: 66px;
  left: 90px;
}

 }
</style>